<div class="placeholder" ref:placeholder>
	<!-- &#8203; -->
	<div
		class="number"
		data-emphasized="{emphasized}"
		data-hidden="{hidden}"
		style="z-index: {zIndex}"
		id={id}
	>
		{number}
	</div>
</div>

<style>.placeholder {
	float: left;
	width: 0;
}

.number {
	position: relative;
	left: -32px;
	margin: 0;

	font-family: "Lato", "Helvetica Neue", Helvetica, sans-serif;
	font-size: .7rem;
	color: #717171;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;

	background-color: rgba(250,250,250,1);
	opacity: 1;
	width: 32px;
}

[data-emphasized=true] {
	font-weight: bold;
	font-size: .9rem;
}

[data-hidden=true] {
	display: none;
}
</style>

<script>
const reallyBig = 1000

export default {
	data() {
		return {
			emphasized: false,
			hidden: false
		}
	},
	computed: {
		zIndex: ({ number, emphasized }) => emphasized
			? reallyBig + number
			: reallyBig - number
	},
}
</script>
